const wDictionary = ["which",
"would",
"world",
"where",
"women",
"power",
"while",
"water",
"white",
"below",
"write",
"works",
"watch",
"words",
"known",
"whole",
"shows",
"views",
"weeks",
"lower",
"woman",
"shown",
"allow",
"brown",
"award",
"owner",
"wrote",
"wrong",
"worth",
"whose",
"waste",
"owned",
"wants",
"sweet",
"wales",
"aware",
"wheel",
"width",
"knows",
"lewis",
"swiss",
"newly",
"tower",
"twice",
"worst",
"crown",
"wings",
"throw",
"worse",
"walls",
"drawn",
"woods",
"wells",
"grown",
"towns",
"worry",
"swing",
"winds"]; export default wDictionary;