const bDictionary = ["about",
"books",
"based",
"black",
"being",
"board",
"table",
"below",
"above",
"beach",
"basic",
"brand",
"album",
"build",
"cable",
"brown",
"built",
"blood",
"basis",
"bring",
"maybe",
"break",
"block",
"blogs",
"begin",
"bible",
"bible",
"buyer",
"label",
"began",
"urban",
"labor",
"birth",
"abuse",
"clubs",
"brain",
"brief",
"bytes",
"banks",
"bonus",
"adobe",
"babes",
"babes",
"boxes",
"buddy",
"blues",
"bears",
"bound",
"boobs",
"boobs",
"broad",
"bands",
"doubt",
"birds",
"boots",
"bills",
"blank",
"blind",
"busty",
"boats",
"fiber",
"bonds",
"baker",
"ebony",
"beast",
"globe",
"brass",
"booty",
"balls",
"boost",
"bread",
"billy",
"rugby",
"barry",
"robot",
"noble"]; export default bDictionary;