const cDictionary = ["which",
"click",
"click",
"price",
"music",
"could",
"local",
"black",
"check",
"check",
"place",
"since",
"class",
"stock",
"march",
"topic",
"court",
"china",
"space",
"cards",
"child",
"color",
"track",
"close",
"beach",
"costs",
"cheap",
"cover",
"watch",
"clear",
"cases",
"quick",
"basic",
"force",
"cross",
"cable",
"score",
"cause",
"focus",
"voice",
"comes",
"match",
"dance",
"block",
"civil",
"peace",
"coast",
"scale",
"chief",
"clean",
"piece",
"cells",
"calls",
"canon",
"chair",
"crime",
"count",
"claim",
"patch",
"creek",
"touch",
"magic",
"facts",
"chain",
"reach",
"chart",
"clubs",
"codes",
"carry",
"clips",
"truck",
"ocean",
"cycle",
"cycle",
"scene",
"cisco",
"cisco",
"clock",
"clock",
"specs",
"cream",
"occur",
"occur",
"coach",
"coach",
"logic",
"costa",
"scope",
"crazy",
"stick",
"exact",
"micro",
"lunch",
"catch",
"catch",
"twice",
"teach",
"dutch",
"grace",
"cache",
"cache",
"comic",
"comic",
"crown",
"crack",
"crack",
"picks",
"craft",
"coins",
"actor",
"crash",
"faces",
"cocks",
"cocks",
"hence",
"shock",
"chile",
"acres",
"excel",
"lucky",
"thick",
"chips",
"candy",
"icons",
"chest",
"nancy"]; export default cDictionary;