const aDictionary = ["about",
"state",
"email",
"after",
"years",
"games",
"great",
"local",
"based",
"black",
"today",
"pages",
"total",
"place",
"media",
"water",
"board",
"small",
"image",
"shall",
"class",
"value",
"learn",
"sales",
"large",
"table",
"start",
"human",
"march",
"yahoo",
"staff",
"again",
"again",
"party",
"legal",
"above",
"rates",
"paper",
"texas",
"range",
"audio",
"china",
"major",
"areas",
"areas",
"space",
"cards",
"share",
"added",
"radio",
"track",
"least",
"trade",
"means",
"daily",
"beach",
"parts",
"early",
"final",
"adult",
"cheap",
"watch",
"deals",
"james",
"heart",
"clear",
"makes",
"taken",
"cases",
"later",
"basic",
"along",
"among",
"death",
"brand",
"japan",
"japan",
"loans",
"album",
"plans",
"apply",
"asked",
"names",
"leave",
"woman",
"cable",
"flash",
"ideas",
"allow",
"cause",
"glass",
"happy",
"thank",
"ready",
"earth",
"basis",
"award",
"award",
"extra",
"rated",
"stars",
"takes",
"agent",
"valid",
"grand",
"trial",
"metal",
"grade",
"panel",
"match",
"plant",
"stage",
"maybe",
"break",
"dance",
"apple",
"hands",
"paris",
"peace",
"coast",
"grant",
"agree",
"scale",
"stand",
"frame",
"heard",
"royal",
"clean",
"vegas",
"calls",
"naked",
"label",
"canon",
"waste",
"chair",
"phase",
"claim",
"patch",
"alone",
"saint",
"dates",
"began",
"urban",
"labor",
"admin",
"heavy",
"goals",
"magic",
"smart",
"avoid",
"abuse",
"facts",
"faith",
"chain",
"reach",
"gamma",
"gamma",
"draft",
"chart",
"equal",
"teams",
"named",
"laser",
"harry",
"taxes",
"brain",
"dream",
"false",
"falls",
"stats",
"carry",
"wants",
"alert",
"ocean",
"train",
"frank",
"anime",
"speak",
"rural",
"banks",
"leads",
"wales",
"ahead",
"ahead",
"delta",
"alpha",
"alpha",
"adobe",
"babes",
"layer",
"ratio",
"ideal",
"parks",
"cream",
"aware",
"aware",
"shape",
"usage",
"angel",
"array",
"array",
"sharp",
"coach",
"plate",
"plain",
"costa",
"trail",
"crazy",
"bears",
"sugar",
"exact",
"paint",
"delay",
"ultra",
"plays",
"broad",
"guard",
"raise",
"drama",
"drama",
"bands",
"audit",
"solar",
"catch",
"tasks",
"marks",
"saved",
"treat",
"piano",
"teach",
"rapid",
"hairy",
"pearl",
"heads",
"honda",
"opera",
"asset",
"blank",
"meant",
"plane",
"grace",
"villa",
"roman",
"taste",
"cache",
"lease",
"giant",
"seats",
"alarm",
"alarm",
"usual",
"angle",
"eagle",
"pants",
"maker",
"crack",
"craft",
"apart",
"apart",
"actor",
"alive",
"boats",
"graph",
"talks",
"fraud",
"crash",
"spray",
"roads",
"faces",
"mayor",
"radar",
"radar",
"lakes",
"diary",
"flags",
"baker",
"walls",
"drawn",
"beast",
"pizza",
"yards",
"maria",
"maria",
"brass",
"plaza",
"plaza",
"trans",
"acres",
"vital",
"vista",
"arena",
"arena",
"lands",
"armed",
"laura",
"laura",
"candy",
"balls",
"moral",
"bread",
"gonna",
"nancy",
"reads",
"fault",
"barry",
"grass",
"manga",
"manga",
"dated"]; export default aDictionary;