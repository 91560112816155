const tDictionary = ["about",
"other",
"their",
"there",
"first",
"these",
"state",
"state",
"after",
"items",
"great",
"hotel",
"store",
"terms",
"right",
"those",
"today",
"south",
"photo",
"three",
"total",
"total",
"think",
"north",
"posts",
"water",
"white",
"times",
"sites",
"title",
"title",
"still",
"visit",
"tools",
"print",
"stock",
"point",
"table",
"start",
"start",
"study",
"staff",
"topic",
"party",
"quote",
"story",
"rates",
"night",
"texas",
"court",
"light",
"write",
"event",
"might",
"month",
"enter",
"until",
"track",
"least",
"trade",
"short",
"costs",
"style",
"front",
"parts",
"adult",
"thing",
"third",
"gifts",
"often",
"watch",
"heart",
"taken",
"later",
"death",
"stuff",
"entry",
"notes",
"types",
"teens",
"smith",
"thank",
"sport",
"built",
"earth",
"peter",
"extra",
"rated",
"quite",
"stars",
"lists",
"takes",
"input",
"agent",
"trial",
"units",
"wrote",
"metal",
"guest",
"trust",
"trust",
"match",
"plant",
"stage",
"youth",
"steel",
"worth",
"coast",
"grant",
"stand",
"suite",
"sheet",
"stone",
"tests",
"tests",
"waste",
"motor",
"shirt",
"count",
"patch",
"saint",
"joint",
"dates",
"limit",
"steps",
"tours",
"theme",
"touch",
"mount",
"smart",
"birth",
"facts",
"faith",
"truth",
"truth",
"draft",
"chart",
"teams",
"tried",
"taxes",
"stats",
"stats",
"eight",
"wants",
"alert",
"sweet",
"truck",
"votes",
"depth",
"train",
"route",
"bytes",
"fight",
"noted",
"spent",
"intel",
"delta",
"trees",
"ratio",
"empty",
"exist",
"width",
"plate",
"costa",
"trail",
"setup",
"mouth",
"meter",
"fruit",
"stick",
"exact",
"storm",
"paint",
"pilot",
"ultra",
"truly",
"audit",
"tower",
"catch",
"doubt",
"tasks",
"forth",
"split",
"twice",
"shift",
"shots",
"treat",
"treat",
"ports",
"teach",
"dutch",
"boots",
"metro",
"strip",
"asset",
"tight",
"tight",
"meant",
"meets",
"taste",
"taste",
"trips",
"turns",
"giant",
"seats",
"worst",
"pants",
"quiet",
"craft",
"apart",
"actor",
"fifth",
"dirty",
"throw",
"trend",
"busty",
"boats",
"tells",
"talks",
"inter",
"beast",
"ghost",
"quest",
"trans",
"booty",
"vital",
"thick",
"vista",
"terry",
"tiger",
"boost",
"tough",
"chest",
"tones",
"sight",
"towns",
"fault",
"theft",
"theft",
"dated",
"shoot",
"elite",
"robot",
"roots"]; export default tDictionary;