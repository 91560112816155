const kDictionary = ["click",
"books",
"links",
"black",
"check",
"think",
"stock",
"poker",
"track",
"works",
"makes",
"taken",
"known",
"quick",
"asked",
"weeks",
"thank",
"takes",
"break",
"block",
"looks",
"naked",
"creek",
"kinds",
"truck",
"frank",
"speak",
"banks",
"kelly",
"drink",
"clock",
"parks",
"greek",
"knows",
"stick",
"tasks",
"marks",
"risks",
"blank",
"maker",
"crack",
"picks",
"smoke",
"skill",
"talks",
"cocks",
"lakes",
"kings",
"baker",
"shock",
"jokes",
"kerry",
"lucky",
"thick",
"folks",
"keeps"]; export default kDictionary;