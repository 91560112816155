const gDictionary = ["group",
"games",
"great",
"right",
"using",
"being",
"pages",
"guide",
"image",
"large",
"going",
"going",
"again",
"login",
"legal",
"young",
"girls",
"night",
"range",
"light",
"given",
"might",
"green",
"thing",
"gifts",
"along",
"among",
"doing",
"glass",
"bring",
"agent",
"grand",
"guest",
"grade",
"stage",
"goods",
"songs",
"wrong",
"grant",
"agree",
"blogs",
"gives",
"begin",
"vegas",
"drugs",
"began",
"goals",
"magic",
"gamma",
"eight",
"signs",
"judge",
"fight",
"rings",
"guess",
"usage",
"angel",
"greek",
"logic",
"sugar",
"genre",
"lodge",
"guard",
"logos",
"tight",
"grace",
"giant",
"angle",
"eagle",
"gross",
"wings",
"ridge",
"graph",
"roger",
"grove",
"kings",
"flags",
"dodge",
"globe",
"ghost",
"grown",
"tiger",
"tough",
"gonna",
"sight",
"glory",
"rugby",
"grass",
"manga",
"swing",
"gnome"]; export default gDictionary;