const sDictionary = ["first",
"these",
"state",
"music",
"books",
"links",
"years",
"items",
"games",
"store",
"terms",
"those",
"using",
"based",
"south",
"pages",
"house",
"posts",
"posts",
"since",
"small",
"times",
"sites",
"sites",
"hours",
"shall",
"class",
"class",
"still",
"visit",
"tools",
"press",
"press",
"stock",
"sales",
"sales",
"start",
"study",
"staff",
"users",
"users",
"story",
"rates",
"girls",
"texas",
"issue",
"issue",
"files",
"needs",
"areas",
"space",
"cards",
"share",
"least",
"close",
"short",
"means",
"costs",
"costs",
"style",
"parts",
"miles",
"sound",
"works",
"rules",
"gifts",
"deals",
"words",
"james",
"makes",
"cases",
"cases",
"basic",
"shows",
"shows",
"speed",
"stuff",
"loans",
"shoes",
"shoes",
"notes",
"views",
"plans",
"types",
"lines",
"asked",
"cross",
"cross",
"weeks",
"names",
"teens",
"score",
"shown",
"flash",
"ideas",
"homes",
"super",
"cause",
"focus",
"rooms",
"comes",
"forms",
"glass",
"glass",
"smith",
"sport",
"basis",
"basis",
"pussy",
"pussy",
"horse",
"stars",
"stars",
"lists",
"lists",
"takes",
"units",
"ships",
"ships",
"funds",
"guest",
"seems",
"seems",
"trust",
"sense",
"sense",
"stage",
"goods",
"steel",
"songs",
"songs",
"hands",
"paris",
"coast",
"blogs",
"scale",
"stand",
"gives",
"suite",
"vegas",
"sheet",
"seven",
"cells",
"looks",
"calls",
"whose",
"lives",
"stone",
"tests",
"tests",
"waste",
"phase",
"shirt",
"jones",
"saint",
"drugs",
"fresh",
"dates",
"louis",
"steps",
"steps",
"shops",
"shops",
"tours",
"solid",
"goals",
"serve",
"smart",
"virus",
"abuse",
"facts",
"sorry",
"films",
"clubs",
"codes",
"kinds",
"teams",
"laser",
"taxes",
"mouse",
"false",
"falls",
"stats",
"stats",
"clips",
"wants",
"sweet",
"votes",
"signs",
"signs",
"feeds",
"speak",
"bytes",
"banks",
"leads",
"wales",
"spent",
"helps",
"sleep",
"scene",
"rings",
"guess",
"guess",
"cisco",
"bonus",
"trees",
"dress",
"dress",
"babes",
"spend",
"specs",
"specs",
"parks",
"boxes",
"hills",
"shape",
"firms",
"usage",
"exist",
"noise",
"sharp",
"knows",
"sizes",
"sizes",
"costa",
"setup",
"blues",
"scope",
"bears",
"lewis",
"sugar",
"stick",
"slide",
"storm",
"dolls",
"plays",
"boobs",
"swiss",
"swiss",
"swiss",
"foods",
"raise",
"bands",
"polls",
"yours",
"shell",
"solar",
"tasks",
"tasks",
"doors",
"split",
"shift",
"marks",
"birds",
"saved",
"shots",
"shots",
"risks",
"risks",
"ports",
"boots",
"holds",
"pulse",
"strip",
"penis",
"heads",
"logos",
"bills",
"asset",
"asset",
"meets",
"taste",
"trips",
"sides",
"sides",
"turns",
"lease",
"seats",
"seats",
"usual",
"worst",
"pants",
"nurse",
"picks",
"smoke",
"coins",
"gross",
"gross",
"finds",
"wings",
"skill",
"moves",
"busty",
"worse",
"boats",
"tells",
"talks",
"bonds",
"crash",
"spray",
"roads",
"faces",
"cocks",
"lakes",
"kings",
"flags",
"shock",
"walls",
"beast",
"yards",
"woods",
"jokes",
"ghost",
"brass",
"brass",
"quest",
"trans",
"acres",
"modes",
"wells",
"opens",
"vista",
"chips",
"smile",
"lands",
"pills",
"folks",
"balls",
"boost",
"icons",
"keeps",
"roses",
"roses",
"chest",
"solve",
"tones",
"sight",
"towns",
"reads",
"roles",
"grass",
"grass",
"sized",
"swing",
"shoot",
"poems",
"winds",
"roots",
"shore",
"loves",
"loose"]; export default sDictionary;