const vDictionary = ["video",
"level",
"every",
"visit",
"value",
"movie",
"never",
"above",
"given",
"event",
"drive",
"cover",
"river",
"views",
"leave",
"voice",
"valid",
"civil",
"gives",
"vegas",
"seven",
"lives",
"heavy",
"serve",
"avoid",
"virus",
"moved",
"votes",
"devel",
"novel",
"loved",
"saved",
"lived",
"villa",
"vinyl",
"alive",
"prove",
"moves",
"grove",
"venue",
"vital",
"vista",
"solve",
"devil",
"loves"]; export default vDictionary;