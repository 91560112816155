const fDictionary = ["first",
"after",
"forum",
"found",
"staff",
"staff",
"field",
"offer",
"offer",
"files",
"front",
"final",
"gifts",
"often",
"stuff",
"stuff",
"force",
"flash",
"focus",
"forms",
"funds",
"floor",
"fixed",
"fully",
"frame",
"chief",
"fresh",
"facts",
"faith",
"films",
"draft",
"funny",
"false",
"falls",
"brief",
"feeds",
"frank",
"fight",
"filed",
"refer",
"proof",
"firms",
"fruit",
"foods",
"forth",
"shift",
"craft",
"finds",
"fifth",
"fifth",
"fiber",
"fraud",
"faces",
"flags",
"flood",
"folks",
"fault",
"fluid",
"theft"]; export default fDictionary;