const yDictionary = ["years",
"today",
"money",
"every",
"reply",
"yahoo",
"study",
"party",
"story",
"young",
"daily",
"style",
"early",
"entry",
"types",
"apply",
"happy",
"ready",
"pussy",
"maybe",
"youth",
"enjoy",
"fully",
"royal",
"buyer",
"heavy",
"sorry",
"funny",
"harry",
"carry",
"query",
"bytes",
"kelly",
"cycle",
"henry",
"layer",
"empty",
"array",
"buddy",
"crazy",
"delay",
"plays",
"truly",
"newly",
"yours",
"hairy",
"vinyl",
"dirty",
"busty",
"spray",
"mayor",
"yield",
"diary",
"ebony",
"yards",
"kerry",
"booty",
"enemy",
"lucky",
"terry",
"jerry",
"candy",
"billy",
"nancy",
"worry",
"glory",
"jimmy",
"rugby",
"barry"]; export default yDictionary;