const pDictionary = ["price",
"group",
"phone",
"pages",
"photo",
"power",
"place",
"posts",
"reply",
"press",
"print",
"point",
"topic",
"party",
"paper",
"paper",
"poker",
"space",
"parts",
"cheap",
"speed",
"japan",
"plans",
"types",
"apply",
"apply",
"super",
"happy",
"happy",
"prior",
"sport",
"peter",
"pussy",
"input",
"ships",
"panel",
"plant",
"apple",
"apple",
"paris",
"peace",
"piece",
"phase",
"patch",
"upper",
"upper",
"prime",
"steps",
"shops",
"porno",
"clips",
"depth",
"speak",
"spent",
"helps",
"sleep",
"alpha",
"spend",
"proof",
"empty",
"specs",
"parks",
"shape",
"sharp",
"plate",
"plain",
"setup",
"scope",
"paint",
"pilot",
"plays",
"polls",
"split",
"piano",
"ports",
"rapid",
"pulse",
"strip",
"pearl",
"penis",
"opera",
"plane",
"trips",
"proud",
"pants",
"picks",
"apart",
"prize",
"prove",
"graph",
"spray",
"pizza",
"pride",
"plaza",
"opens",
"chips",
"pills",
"keeps",
"pound",
"poems"]; export default pDictionary;