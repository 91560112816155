const hDictionary = ["other",
"which",
"which",
"their",
"there",
"these",
"where",
"hotel",
"right",
"those",
"phone",
"check",
"south",
"house",
"photo",
"while",
"three",
"think",
"north",
"white",
"hours",
"shall",
"human",
"march",
"yahoo",
"night",
"light",
"china",
"might",
"month",
"child",
"share",
"short",
"beach",
"thing",
"cheap",
"third",
"watch",
"heart",
"whole",
"shows",
"death",
"shoes",
"shown",
"flash",
"homes",
"happy",
"smith",
"thank",
"earth",
"horse",
"ships",
"match",
"youth",
"hands",
"worth",
"chief",
"heard",
"sheet",
"whose",
"chair",
"phase",
"shirt",
"patch",
"fresh",
"shops",
"heavy",
"theme",
"touch",
"birth",
"faith",
"chain",
"reach",
"truth",
"chart",
"harry",
"hello",
"eight",
"depth",
"fight",
"helps",
"henry",
"ahead",
"alpha",
"hills",
"shape",
"wheel",
"width",
"sharp",
"coach",
"mouth",
"lunch",
"shell",
"catch",
"forth",
"shift",
"shots",
"teach",
"hairy",
"dutch",
"holds",
"heads",
"honda",
"humor",
"tight",
"cache",
"honor",
"fifth",
"throw",
"graph",
"crash",
"hence",
"shock",
"ghost",
"chile",
"thick",
"chips",
"tough",
"chest",
"sight",
"theft",
"shoot",
"shore"]; export default hDictionary;