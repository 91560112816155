const rDictionary = ["other",
"their",
"there",
"first",
"price",
"world",
"after",
"where",
"years",
"order",
"order",
"group",
"under",
"great",
"store",
"terms",
"right",
"forum",
"power",
"three",
"north",
"water",
"board",
"hours",
"every",
"reply",
"press",
"learn",
"print",
"large",
"start",
"march",
"never",
"users",
"party",
"story",
"rates",
"paper",
"girls",
"poker",
"range",
"court",
"write",
"offer",
"major",
"areas",
"cards",
"enter",
"share",
"radio",
"color",
"track",
"trade",
"green",
"drive",
"short",
"front",
"parts",
"early",
"works",
"rules",
"third",
"cover",
"words",
"heart",
"error",
"error",
"error",
"clear",
"later",
"brand",
"entry",
"force",
"river",
"river",
"cross",
"lower",
"score",
"super",
"rooms",
"brown",
"forms",
"prior",
"prior",
"sport",
"ready",
"round",
"earth",
"award",
"peter",
"extra",
"rated",
"horse",
"stars",
"owner",
"bring",
"grand",
"trial",
"wrote",
"trust",
"grade",
"floor",
"break",
"wrong",
"paris",
"worth",
"grant",
"agree",
"frame",
"heard",
"royal",
"older",
"buyer",
"chair",
"motor",
"shirt",
"crime",
"drugs",
"fresh",
"upper",
"prime",
"creek",
"urban",
"tours",
"labor",
"porno",
"serve",
"smart",
"birth",
"virus",
"reach",
"sorry",
"sorry",
"truth",
"draft",
"chart",
"tried",
"laser",
"harry",
"harry",
"brain",
"dream",
"carry",
"carry",
"brief",
"alert",
"truck",
"train",
"route",
"frank",
"query",
"rural",
"rural",
"minor",
"drink",
"rings",
"henry",
"trees",
"dress",
"refer",
"refer",
"layer",
"ratio",
"proof",
"parks",
"cream",
"aware",
"firms",
"array",
"array",
"greek",
"sharp",
"occur",
"trail",
"crazy",
"bears",
"meter",
"fruit",
"sugar",
"genre",
"storm",
"micro",
"ultra",
"truly",
"broad",
"guard",
"raise",
"drama",
"tower",
"yours",
"solar",
"doors",
"forth",
"marks",
"birds",
"treat",
"risks",
"ports",
"rapid",
"hairy",
"metro",
"strip",
"pearl",
"opera",
"humor",
"grace",
"inner",
"roman",
"trips",
"turns",
"proud",
"alarm",
"worst",
"honor",
"nurse",
"crown",
"maker",
"crack",
"craft",
"apart",
"gross",
"actor",
"prize",
"dirty",
"prove",
"ridge",
"throw",
"trend",
"worse",
"fiber",
"graph",
"fraud",
"roger",
"roger",
"crash",
"inter",
"grove",
"spray",
"roads",
"mayor",
"radar",
"radar",
"diary",
"baker",
"drawn",
"yards",
"kerry",
"kerry",
"pride",
"maria",
"brass",
"trans",
"acres",
"terry",
"terry",
"arena",
"grown",
"jerry",
"jerry",
"armed",
"laura",
"tiger",
"moral",
"roses",
"bread",
"worry",
"worry",
"reads",
"roles",
"glory",
"rugby",
"barry",
"barry",
"grass",
"robot",
"roots",
"shore"]; export default rDictionary;